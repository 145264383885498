import axios from "axios";

const Url = process.env.REACT_APP_URl;

/*image content */

export const ResidentialTypeService = async (obj: any) => {
  const apiUrl = Url + "/detect_room_type_residencial";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTk1ODU3MGU2Y2NlOWU5OTM1Njg5ZDEiLCJpYXQiOjE3MDU2Njk4OTYsImV4cCI6MTg0MTIzODY5Nn0.zhdBas8riYPwsc_rw0E5gHGO3kvi-V1L2zxz9ui2O2w"
    }
  }
  )
  return response.data
}
export const GenerateRoomStaging = async (generateObj:any) => {
  const apiUrl=Url+"/generate_images_plugin_grouped";
 const response= await axios.post(apiUrl,generateObj,{
  headers: {
    "Authorization": "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTk1ODU3MGU2Y2NlOWU5OTM1Njg5ZDEiLCJpYXQiOjE3MDU2Njk4OTYsImV4cCI6MTg0MTIzODY5Nn0.zhdBas8riYPwsc_rw0E5gHGO3kvi-V1L2zxz9ui2O2w"
  }
})
localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const Getofflineplugin = async (generateObj:any) => {
  const apiUrl=Url+"/getofflineplugin";
 const response= await axios.post(apiUrl,generateObj,{
  headers: {
    "Authorization": "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTk1ODU3MGU2Y2NlOWU5OTM1Njg5ZDEiLCJpYXQiOjE3MDU2Njk4OTYsImV4cCI6MTg0MTIzODY5Nn0.zhdBas8riYPwsc_rw0E5gHGO3kvi-V1L2zxz9ui2O2w"
  }
})
localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const Gethtmlcontent = async (generateObj:any) => {
  const apiUrl=Url+"/fetchurl";
 const response= await axios.post(apiUrl,{"url":generateObj})
  return response.data;
  
}

