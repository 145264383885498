import { useEffect, useState } from "react";
import { Property } from "../Models/Property";
import { Box, Button, CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";
import { GenerateRoomStaging, Gethtmlcontent, Getofflineplugin, ResidentialTypeService } from "../Services/generate_room_service";


const Home: React.FunctionComponent<{}> = (props) => {
    const location = useLocation();

    // Function to parse query parameters
    const parseQueryString = () => {
        const searchParams = new URLSearchParams(location.search);
        const lien = searchParams.get('url');
        console.log("lien",lien)
        if (lien) {
            return lien
        }
        return null;
    };
    const [propertyValue, setPropertyValue] = useState<Property>({})
    const [selectStyle, setSelectStyle] = useState<any>("");
    const [isPropertyData, setIsPropertyData] = useState<boolean>(true);
    const [isUpdateImage, setIsUpdateImage] = useState<boolean>(false);
    const [selectImages, setSelectImages] = useState<any>([]);
    const [result, setResult] = useState<any>([])
    const [changedId, setChangedId] = useState<any>()
    const fetchData = async (list_images :any) => {
        const url =parseQueryString();
        try {
            console.log("fetch",url)
            const response = await  Gethtmlcontent(url);
           
            const parser = new DOMParser();
            
            const doc = parser.parseFromString(response, 'text/html');
            
            const list_title = doc.querySelectorAll('.property-desc h3');
            const titles = Array.from(list_title).map((title: any) => (title.innerText));
            const price: any = doc.querySelector('.flexslider .yellow-btn');
            const images = doc.querySelectorAll('.image-wrapper img');
            const items = doc.querySelectorAll(".slide-item-features li ")
            const list_items = Array.from(items).map((item: any) => (item.innerText));
            const sources = Array.from(images).map((img, index) => ({ src: img.getAttribute('src') || '', id: index }));
            console.log(list_items, sources)
            setPropertyValue({
                category: "Apartment",
                bathrooms_count: list_items[1],
                bedrooms_count: list_items[2],
                price: price.innerText,
                size: list_items[0],
                title: titles[0],
                images: list_images
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDataImages = async () => {
        const url =parseQueryString();
        try {
            const response = await  Gethtmlcontent(url);
           
            const parser = new DOMParser();
            
            
        const doc = parser.parseFromString(response, 'text/html');
            
            const list_title = doc.querySelectorAll('.property-desc h3');
            const titles = Array.from(list_title).map((title: any) => (title.innerText));
            const price: any = doc.querySelector('.flexslider .yellow-btn');
            const images = doc.querySelectorAll('.image-wrapper img');
            const items = doc.querySelectorAll(".slide-item-features li ")
            const list_items = Array.from(items).map((item: any) => (item.innerText));
            const sources = Array.from(images).map((img, index) => ({ src: img.getAttribute('src') || '', id: index }));
            console.log(list_items, sources)
            setPropertyValue({
                category: "Apartment",
                bathrooms_count: list_items[1],
                bedrooms_count: list_items[2],
                price: price.innerText,
                size: list_items[0],
                title: titles[0],
                images: sources
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const url =parseQueryString();
        const obj ={
            "id": url
        }
        Getofflineplugin(obj).then((data :any)=>{
            fetchData(data.images);
            setResult(data.results)
            setIsPropertyData(false)
        }).catch((error)=>{
            if (error.response.status === 404) {
                fetchDataImages()
            } 
        })
    
    }, []);

    useEffect(() => {
        if (propertyValue.images && propertyValue.images.length > 0 && result.length == 0) {
            ResidentialTypeService(propertyValue.images).then((images) => {
                setPropertyValue(prevState => ({
                    ...prevState,
                    images: images.data.data
                }));
                Generate(images.data.data)
            }).catch((error) => {
                console.log("error")
            });
        }
    }, [propertyValue.title]);
    function getTypeById(id:any) {
        const image = propertyValue.images?.find(image => image.id === id);
        return image ? (image.type || image.room_type) : null;
    }
    const handleSelectChangeStyle = (event: any, image: any) => {
        setIsUpdateImage(true);
        setChangedId(image.img_before.id)
        setSelectStyle(event.target.value);

        const obj = {
            room_type: "Living Room",
            style: event.target.value,
            environment: "Interior",
            images: [{
                "id": image.img_before.id,
                "src": image.img_before.src,
                "type":getTypeById(image.img_before.id)
            }]
        };
        
        GenerateRoomStaging(obj).then((data) => {
            console.log(data.result[0].images[0].img_after.src);
            setIsUpdateImage(false);
            setChangedId('');
            const updatedResult = result.map((items: any) => {
                return {
                    ...items,
                    images: items.images.map((item: any) => {
                        if (item.img_before.id === image.img_before.id) {
                            return {
                                img_before: item.img_before,
                                img_after: {
                                    src: data.result[0].images[0].img_after.src,
                                    id: item.img_after.id
                                }
                            };
                        }
                        return item;
                    })
                };
            });
            setResult(updatedResult);
        }).catch((error) => {
            console.error("Erreur lors de la mise à jour de l'image :", error);
            setIsUpdateImage(false);
        });
    };

    const handleSelectImages = (image: any) => {
        setSelectImages((prevSelectedImages: any) =>
            prevSelectedImages.includes(image)
                ? prevSelectedImages.filter((selectedImage: any) => selectedImage !== image)
                : [...prevSelectedImages, image]
        );
    }

    const formatString = (str: any) => {
        if (str) {

            let words = str.split(/[-_]/);

            words = words.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1));
            return words.join(' ');
        }
    }

    const Generate = async (images: any) => {
        const obj = {
            room_type: "Living Room",
            style: "Modern",
            environment: "Interior",
            images: images
        }
        const imgs = await GenerateRoomStaging(obj)
        const result_obj={
            property_ref: `https://www.realestate.coralytics.com/`+parseQueryString(),
            images : images,
            result : imgs
        }
        console.log(result_obj)
        setIsPropertyData(false)
        setResult(imgs.result)
    }
    const handleDownload = async (image: any) => {
        const imageName = 'image.jpg';
        try {
            const response = await fetch(image);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = imageName;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };
    function getRoomType(type:any) {
        if (type.toLowerCase() === "living_room") {
          return "Living Room";
        } else if (type.toLowerCase().includes("office")) {
          return "Office Room";
        } else if (type.toLowerCase() === "room-bedroom") {
          return "Bedroom";
        } else if (type.toLowerCase() === "bathroom") {
          return "Bathroom";
        } else if (type.toLowerCase() === "dining_room") {
          return "Dining Area";
        } else if (type.toLowerCase() === "kitchen") {
          return "Kitchen";
        } else if (type.toLowerCase() === "terrace") {
          return "Terrace";
        } else if (type.toLowerCase() === "balcony") {
          return "Balcony";
        } else {
          return "non";
        }
      }
    return (

        <div style={{ padding: "20px 20px" }}>
            <div className="d-flex justify-content-end align-items-center">
                <p className="powerd_p" style={{color:"#B5C0D0"}} >Powerd by </p>
                <img src="https://coralytics.com/images/Coralytics trans.png" width="100" height="30" alt="logo" />
            </div>
            <div className="mbs-3">
                <h2>{propertyValue.title}</h2>
            </div>

            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="mb-4" style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div className="bed"><i className="fa-solid fa-house" style={{ color: "#716BE3" }}></i> {propertyValue.category}</div>
                    </div>
                    <div className="mb-4" style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div className="bed"><i className="fa-solid fa-couch" style={{ color: "#716BE3" }}></i> {propertyValue.bedrooms_count}</div>
                    </div>
                    <div className="mb-4" style={{ display: 'inline-block', marginRight: '20px' }}>
                        <div className="bed"><i className="fa-solid fa-table-cells" style={{ color: "#716BE3" }}></i> {propertyValue.size} </div>
                    </div>
                    <div className="mb-4" style={{ display: 'inline-block' }}>
                        <div className="bed"><i className="fa-solid fa-money-bill" style={{ color: "#716BE3" }}></i> {propertyValue.price}</div>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12">
                    <h4 className="mb-4 generate-h5">Overview</h4>
                    <div className="image-list" >

                        {propertyValue.images ? propertyValue.images.map((image: any, index: any) => (
                          <div key={`previewImage-${index}`}>
                            {getRoomType(image.type)!="non"? <><label htmlFor={`previewImage-${index}`} className="label">
                                    <img src={image.src} className="image" id={`previewImage-${index}`} key={`previewImage-${index}`} />
                                </label><p>{formatString(image.type)}</p></>:<div> </div>}
                              
                            </div>)) : <Box sx={{ display: 'flex' }}>
                            <CircularProgress color="secondary" />
                        </Box>}
                    </div>
                </div>
            </div>
            <hr style={{ border: "none", backgroundColor: "#000", margin: "60px 0", height: "3px" }} />
            <div className="row mb-4">
                <h4 className="mb-4 generate-h5" > Generated Room</h4>
            </div>
            {isPropertyData && <div className='' style={{ textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="secondary" />
                </Box>
            </div>}
            {result && result.map((result_obj: any, index: any) => {
                return <div className="mb-3" key={"type_" + index}>
                    <h4 className='type_room' style={{ marginBottom: "10px" }}>{formatString(result_obj.room_type)}</h4>
                    {result_obj.images.map((image: any, index: any) => {
                        return <div className="card-dash mb-3" key={index}>
                            <div className="card-body-dash">
                                <div className="row ">
                                    <div className="col-md-6 mb-2">
                                            <img src={image.img_before.src} key={"after_" + index} className="img-his" />
                                            <div className="header-img--block header-img--block-before">Before</div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        {isUpdateImage && changedId === image.img_after.id ? <div className='' style={{ textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress color="secondary" />
                                            </Box>
                                        </div> : <div className="image-hover"><img src={image.img_after.src} key={"before_" + index} className="img-his" id="img-after" />
                                            <div className="header-img--block header-img--block-after">After</div>
                                            <button className="header-download--block" onClick={() => handleDownload(image.img_after.src)}> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.0251 1.04199 12.5001V7.50008C1.04199 2.97508 2.97533 1.04175 7.50033 1.04175H12.5003C17.0253 1.04175 18.9587 2.97508 18.9587 7.50008V12.5001C18.9587 17.0251 17.0253 18.9584 12.5003 18.9584ZM7.50033 2.29175C3.65866 2.29175 2.29199 3.65841 2.29199 7.50008V12.5001C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5001V7.50008C17.7087 3.65841 16.342 2.29175 12.5003 2.29175H7.50033Z" fill="white" />
                                                <path d="M9.99987 12.7167C9.84154 12.7167 9.6832 12.6583 9.5582 12.5333L7.0582 10.0333C6.81654 9.79166 6.81654 9.39167 7.0582 9.15C7.29987 8.90833 7.69987 8.90833 7.94153 9.15L9.99987 11.2083L12.0582 9.15C12.2999 8.90833 12.6999 8.90833 12.9415 9.15C13.1832 9.39167 13.1832 9.79166 12.9415 10.0333L10.4415 12.5333C10.3165 12.6583 10.1582 12.7167 9.99987 12.7167Z" fill="white" />
                                                <path d="M10 12.7167C9.65833 12.7167 9.375 12.4334 9.375 12.0917V5.42505C9.375 5.08338 9.65833 4.80005 10 4.80005C10.3417 4.80005 10.625 5.08338 10.625 5.42505V12.0917C10.625 12.4417 10.3417 12.7167 10 12.7167Z" fill="white" />
                                                <path d="M9.99971 15.1916C8.24138 15.1916 6.47471 14.9083 4.79971 14.35C4.47471 14.2416 4.29971 13.8833 4.40804 13.5583C4.51638 13.2333 4.86637 13.05 5.19971 13.1666C8.29971 14.2 11.708 14.2 14.808 13.1666C15.133 13.0583 15.4914 13.2333 15.5997 13.5583C15.708 13.8833 15.533 14.2416 15.208 14.35C13.5247 14.9166 11.758 15.1916 9.99971 15.1916Z" fill="white" />
                                            </svg></button>
                                            <div className="select-style">
                                                <FormControl fullWidth>
                                                    <Select
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Change style' }}
                                                        value={changedId === image.img_after.id ? selectStyle : ""}
                                                        onChange={(event: any) => { handleSelectChangeStyle(event, image) }}>
                                                        <MenuItem key={0} value="" disabled> Change style </MenuItem>
                                                        <MenuItem key={1} value={"Eclectic"}>Eclectic</MenuItem>
                                                        <MenuItem key={2} value={"Cottage"}>Cottage</MenuItem>
                                                        <MenuItem key={3} value={"Scandinavian"}>Scandinavian</MenuItem>
                                                        <MenuItem key={4} value={"Coastal"}>Coastal</MenuItem>
                                                        <MenuItem key={5} value={"Traditional"}>Traditional</MenuItem>
                                                        <MenuItem key={6} value={"Bohemian"}>Bohemian</MenuItem>
                                                        <MenuItem key={7} value={"Art Deco"}>Art Deco</MenuItem>
                                                        <MenuItem key={8} value={"Contemporary"}>Contemporary</MenuItem>
                                                        <MenuItem key={9} value={"Modern"}>Modern</MenuItem>
                                                        <MenuItem key={10} value={"Midcentury Modern"}>Midcentury Modern</MenuItem>
                                                        <MenuItem key={11} value={"Country"}>Country</MenuItem>
                                                        <MenuItem key={12} value={"Shabby Chic"}>Shabby Chic</MenuItem>
                                                        <MenuItem key={13} value={"Modern Farmhouse"}>Modern Farmhouse</MenuItem>
                                                        <MenuItem key={14} value={"Americana"}>Americana</MenuItem>
                                                        <MenuItem key={15} value={"French Country"}>French Country</MenuItem>
                                                        <MenuItem key={16} value={"Transitional"}>Transitional</MenuItem>
                                                        <MenuItem key={17} value={"Hollywood Regency"}>Hollywood Regency</MenuItem>
                                                        <MenuItem key={18} value={"Chinoiserie"}>Chinoiserie</MenuItem>
                                                        <MenuItem key={19} value={"Mediterranean"}>Mediterranean</MenuItem>
                                                        <MenuItem key={20} value={"Tropical"}>Tropical</MenuItem>
                                                        <MenuItem key={21} value={"Minimalist"}>Minimalist</MenuItem>
                                                        <MenuItem key={22} value={"Maximalist"}>Maximalist</MenuItem>
                                                        <MenuItem key={23} value={"Rustic"}>Rustic</MenuItem>
                                                        <MenuItem key={24} value={"Romantic"}>Romantic</MenuItem>
                                                        <MenuItem key={25} value={"Asian"}>Asian</MenuItem>
                                                    </Select>
                                                </FormControl></div></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            })}
        </div>
    )
}
export default Home;